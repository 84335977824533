// reference
// < 360 smallmobile
// < 480 mediummobile
// < 767 mobile
// < 992 ipadMobile
// < 1120 ipadProMobile
// > 992 desktop
// > 1440 largedesktop
// > 2400 extralargedesktop
:root{
    -dark-surface-600: #7a7a7a;
    --dark-surface-700: #666;
    --dark-text-200: #f1f1f1;
    --dark-surface-850: #2f3135;
    --dark-surface-875: #292a2f;
    --dark-surface-base: #1d1e23;
    --dark-surface-900: #242529;
    --dark-surface-pure: #000;
    --dark-surface-800: #464646;
  }
  
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

.v1--styles {
    .m-0 {
        margin: 0px;
    }
    .z-9 {
        position: relative;
        z-index: 9;
    }
    .text-100 {
        color: #f6f6f6;
    }
    .text-200 {
        color: #f1f1f1;
    }
    .white {
        color: #ffffff;
    }
    .text-400 {
        color: #c2c2c2 !important;
    }

    .fs-16px {
        font-size: 16px;
    }

    .fs-36px {
        font-size: 36px;
    }
    .fs-56px {
        @include respond(desktop) {
            font-size: 56px !important;
        }
        @include respond(mobile) {
            font-size: 24px !important;
        }
    }

    .fw-300 {
        font-weight: 300;
    }
    .fw-400 {
        font-weight: 400;
    }
    .fw-500 {
        font-weight: 500;
    }
    .fw-600 {
        font-weight: 600;
    }
    .fw-700 {
        font-weight: 700;
    }

    .fs-italic {
        font-style: italic;
    }
    .modal-high-index {
        z-index: 1500;
    }
    .fs-8px {
        font-size: 8px;
    }
    .fs-9px {
        font-size: 9px;
    }
    .fs-10px {
        font-size: 10px;
    }
    .fs-11px {
        font-size: 11px;
    }
    .fs-12px {
        font-size: 12px !important;
    }
    .fs-13px {
        font-size: 13px;
    }
    .fs-14px {
        font-size: 14px;
    }
    .fs-15px {
        font-size: 15px;
    }
    .fs-16px {
        font-size: 16px;
    }
    .fs-18px {
        font-size: 18px;
    }
    .fs-20px {
        font-size: 20px;
    }
    .fs-24px {
        font-size: 24px;
    }

    .gap-4px {
        gap: 4px;
    }
    .gap-6px {
        gap: 6px;
    }
    .gap-8px {
        gap: 8px;
    }
    .gap-10px {
        gap: 10px;
    }
    .gap-12px {
        gap: 12px;
    }
    .gap-14px {
        gap: 14px;
    }
    .gap-16px {
        gap: 16px;
    }
    .gap-20px {
        gap: 20px;
    }
    .gap-24px {
        gap: 24px;
    }
    .gap-30px {
        gap: 30px;
    }
    .gap-32px {
        gap: 32px;
    }

    .outline-0 {
        outline: none;
    }

    .h-w-28px {
        height: 28px;
        width: 28px;
    }

    /** Padding **/
    .p-24-16 {
        padding: 24px 16px;
    }
    .p-4px {
        padding: 4px;
    }
    .p-4-8 {
        padding: 4px 8px;
    }
    .p-6 {
        padding: 6px;
    }
    .p-6-6-0 {
        padding: 6px 6px 0;
    }
    .px-6 {
        padding: 0px 6px;
    }
    .py-6 {
        padding: 6px 0px;
    }
    .p-8 {
        padding: 8px;
    }
    .p-4-9 {
        padding: 4px 9px;
    }
    .pt-8 {
        padding-top: 8px;
    }
    .pb-8 {
        padding-bottom: 8px;
    }
    .pr-8 {
        padding-right: 8px;
    }
    .pl-8 {
        padding-left: 8px;
    }
    .px-8 {
        padding-right: 8px;
        padding-left: 8px;
    }
    .py-8 {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .p-11 {
        padding: 11px;
    }
    .p-12 {
        padding: 12px;
    }
    .pt-12 {
        padding-top: 12px;
    }
    .pb-12 {
        padding-bottom: 12px;
    }
    .pr-12 {
        padding-right: 12px;
    }
    .pl-12 {
        padding-left: 12px;
    }
    .px-12 {
        padding-right: 12px;
        padding-left: 12px;
    }
    .py-12 {
        padding-top: 12px;
        padding-bottom: 12px !important;
    }

    .p-16 {
        padding: 16px;
    }
    .pt-16 {
        padding-top: 16px;
    }
    .pt-16 {
        padding-top: 16px;
    }
    .pb-16 {
        padding-bottom: 16px;
    }
    .pr-16 {
        padding-right: 16px;
    }
    .pl-16 {
        padding-left: 16px;
    }
    .px-16 {
        padding-right: 16px;
        padding-left: 16px;
    }
    .py-16 {
        padding-top: 16px;
        padding-bottom: 16px !important;
    }

    .p-20 {
        padding: 20px;
    }
    .px-20 {
        padding-right: 20px;
        padding-left: 20px;
    }
    .py-20 {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .p-10 {
        padding: 10px;
    }
    .p-2-8 {
        padding: 2px 8px;
    }
    .p-4-6 {
        padding: 4px 6px;
    }
    .pr-6 {
        padding-right: 6px;
    }
    .p-8-4-8-16 {
        padding: 8px 4px 8px 16px;
    }
    /** End of Padding **/

    /** Margin **/
    .m-8 {
        margin: 8px;
    }
    .mt-8 {
        margin-top: 8px;
    }
    .mb-8 {
        margin-bottom: 8px;
    }
    .mr-8 {
        margin-right: 8px;
    }
    .ml-8 {
        margin-left: 8px;
    }
    .mx-8 {
        margin-right: 8px;
        margin-left: 8px;
    }
    .my-8 {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .m-12 {
        margin: 12px;
    }
    .mt-12 {
        margin-top: 12px;
    }
    .mb-12 {
        margin-bottom: 12px;
    }
    .mr-12 {
        margin-right: 12px;
    }
    .ml-12 {
        margin-left: 12px;
    }
    .mx-12 {
        margin-right: 12px;
        margin-left: 12px;
    }
    .my-12 {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .m-16 {
        margin: 16px;
    }
    .mt-16 {
        margin-top: 16px;
    }
    .mt-16 {
        margin-top: 16px;
    }
    .mb-16 {
        margin-bottom: 16px;
    }
    .mr-16 {
        margin-right: 16px;
    }
    .ml-16 {
        margin-left: 16px;
    }
    .mx-16 {
        margin-right: 16px;
        margin-left: 16px;
    }
    .my-16 {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    /** End of Margin **/

    /** Opacity **/

    .o-0 {
        opacity: 0;
    }
    .o-01 {
        opacity: 0.1;
    }
    .o-02 {
        opacity: 0.2;
    }
    .o-03 {
        opacity: 0.3;
    }
    .o-04 {
        opacity: 0.4;
    }
    .o-05 {
        opacity: 0.5;
    }
    .o-06 {
        opacity: 0.6;
    }
    .o-07 {
        opacity: 0.7;
    }
    .o-08 {
        opacity: 0.8;
    }
    .o-09 {
        opacity: 0.9;
    }
    .o-1 {
        opacity: 1;
    }

    /** End of Opacity **/

    /* Transistion */
    .tran-05-ease-in-out {
        transition: 0.5 ease-in-out;
    }
    /* End of Transistion */

    .w-fit {
        width: fit-content;
    }
    .w-42 {
        width: 42%;
    }
    .w-80 {
        width: 80%;
    }

    .h-80vh {
        height: 80vh;
    }
    .h-70vh {
        height: 70vh;
    }
    .mh-70vh {
        min-height: 70vh !important;
    }
    .h-60vh {
        height: 60vh;
    }
    .mh-60vh {
        min-height: 60vh !important;
    }
    .h-50vh {
        height: 50vh;
    }
    .h-40vh {
        height: 40vh;
    }
    .h-30vh {
        height: 30vh;
    }
    .h-20vh {
        height: 20vh;
    }

    .h-fit {
        height: fit-content !important;
    }
    .h-32 {
        height: 32px;
    }
    .w-55 {
        width: 55%;
    }
    .w-45 {
        width: 45%;
    }

    .w-25 {
        width: 25%;
    }

    .w-30 {
        width: 30%;
    }
    .w-40 {
        width: 40%;
    }
    .w-45 {
        width: 45%;
    }
    .w-55 {
        width: 55%;
    }
    .w-60 {
        width: 60%;
    }

    .h-40 {
        height: 40%;
    }
    .h-60 {
        height: 60%;
    }

    .br-0 {
        border-radius: 0px !important;
    }
    .br-6 {
        border-radius: 6px;
    }
    .br-2 {
        border-radius: 2px;
    }
    .br-3 {
        border-radius: 3px;
    }
    .br-4 {
        border-radius: 4px;
    }
    .br-11 {
        border-radius: 11px !important;
    }
    .br-12 {
        border-radius: 12px !important;
    }
    .br-36 {
        border-radius: 36px;
    }
    .br-38 {
        border-radius: 38px;
    }
    .br-8 {
        border-radius: 8px !important;
    }
    .br-10 {
        border-radius: 10px;
    }
    .br-200 {
        border-radius: 200px;
    }
    .br-46 {
        border-radius: 46px;
    }

    .br-tl-4 {
        border-top-left-radius: 4px;
    }
    .br-tr-4 {
        border-top-right-radius: 4px;
    }
    .br-bl-4 {
        border-bottom-left-radius: 4px;
    }
    .br-br-4 {
        border-bottom-right-radius: 4px;
    }
    .br-tl-0 {
        border-top-left-radius: 0px;
    }
    .br-tr-0 {
        border-top-right-radius: 0px;
    }

    .br-tl-8 {
        border-top-left-radius: 8px;
    }
    .br-tr-8 {
        border-top-right-radius: 8px;
    }
    .br-bl-8 {
        border-bottom-left-radius: 8px;
    }
    .br-br-8 {
        border-bottom-right-radius: 8px;
    }

    .b-tl-10 {
        border-top-left-radius: 10px;
    }
    .b-tr-10 {
        border-top-right-radius: 10px;
    }
    .px-6 {
        padding: 0px 6px;
    }
    .p-2-4 {
        padding: 2px 4px;
    }
    .p-8-14 {
        padding: 8px 8px 14px;
    }

    .surface-900 {
        background: #242529;
    }
    .surface-875 {
        background: #292a2f;
    }
    .surface-800 {
        background: #464646;
    }

    .text-50 {
        color: #fbfbfb;
    }
    .text-100 {
        color: #f6f6f6;
    }
    .text-200 {
        color: #f1f1f1 !important;
    }
    .text-300 {
        color: #e4e4e4;
    }
    .text-400 {
        color: #c2c2c2 !important;
    }
    .text-white {
        color: #ffffff;
    }

    .blue-09 {
        color: #0091ff;
    }

    .hover-bg:hover {
        cursor: pointer;
        background: #292a2f;
    }
    .surface-850 {
        background: #323232;
        &.important {
            background: #323232 !important;
        }
    }
    .hover-surface-850:hover {
        background: #323232;
        cursor: pointer;
    }
    .surface-base {
        background: #212121 !important;
    }

    .fs-56px {
        @include respond(desktop) {
            font-size: 56px !important;
        }
        @include respond(mobile) {
            font-size: 24px !important;
        }
    }
    .cursor-pointer {
        cursor: pointer;
    }

    .inter {
        font-family: Inter;
        font-style: normal;
        letter-spacing: -0.02em;
    }

    .quoteSec {
        @include respond(desktop) {
            // width: 1174px !important;
            height: fit-content !important;
        }
        @include respond(largedesktop) {
            // width: 1440px !important;
            height: fit-content !important;
        }
    }
    .page-jumbotron-v2 {
        // .header-break{
        //     display: block;
        // }
        .sub-header-1 {
            @include respond(desktop) {
                font-size: 18px;
            }
            @include respond(mobile) {
                font-size: 14px;
            }
        }

        @include respond(desktop) {
            .header-break {
                display: none;
            }
            .devQuote {
                position: absolute;
                top: -3rem;
                width: 50rem;
                left: 25%;
                z-index: 11;
            }
            .imgs {
                height: 346px;
                width: 612px;
            }
        }
        @include respond(largedesktop) {
            .header-break {
                display: block;
            }
            .devQuote {
                position: absolute;
                top: -3rem;
                width: 50rem;
                left: 15%;
                z-index: 11;
            }
            .imgs {
                height: 407px;
                width: 720px;
            }
        }
    }

    .homediv {
        background-color: $dark-surface-875;
        border-radius: 8px;
    }

    .devMain {
        margin-bottom: 10%;
        position: relative;
        @include respond(ipad) {
            display: none;
        }
        @include respond(mobile) {
            display: none;
        }
        @include respond(smallmobile) {
            display: none;
        }
    }

    .devMainMobile {
        margin-top: 15%;
        margin-bottom: 7%;

        @include respond(desktop) {
            display: none;
        }
        @include respond(largedesktop) {
            display: none;
        }
    }
    .containerMob {
        color: white;
        padding: 24px 16px;
        border-radius: 8px;
        @include respond(desktop) {
            padding: 17px 16px;
        }
        @include respond(mobile) {
            .containerMobImageQuote {
                display: none;
            }
        }
    }
    .devQuoteMain {
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(18px);
        border: 1px solid #2f3135;
        position: relative;
        border-radius: 10px;
        text-align: center;
        padding: 20px;

        &__text {
            font-family: "Inter";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 170%;
            /* or 27px */

            text-align: center;
            letter-spacing: -0.02em;

            /* Astrum/Dark/Text/200 */

            color: #f1f1f1;
        }
    }
    .devQuote-container {
        position: relative;
        .devQuote {
            position: absolute;
            width: calc(100% - 10rem);
            .border-gradient {
                position: relative;
                border: 1px solid transparent;
                border-radius: 10px;
                background: rgba(0, 0, 0, 1);
                background-clip: padding-box;
                text-align: center;
                padding: 20px;
            }

            .border-gradient::after {
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
                background: linear-gradient(
                    to left,
                    rgba(0, 0, 0, 0.3),
                    #646464
                );
                content: "";
                z-index: -1;
                border-radius: 10px;
            }
        }
    }

    .devQuoteInner {
        position: relative;
    }

    .devQuoteMain {
        font-family: "Inter";
    }

    @include respond(desktop) {
        .blockStyle {
            height: rem(1600) !important;
            position: relative !important;
            scroll-behavior: smooth;
        }
        .childStyle {
            position: sticky;
            top: 7rem;
        }
    }
    @include respond(smalldesktop) {
        .blockStyle {
            height: rem(1600) !important;
            position: relative !important;
            scroll-behavior: smooth;
        }
        .childStyle {
            position: sticky;
            top: 5rem;
        }
    }

    .activeCard {
        @include respond(desktop) {
            position: relative;
            border: 1px solid transparent;
            border-radius: 10px;
            background-clip: padding-box;
            padding: 20px;
            border-width: 1px;
            border-style: solid;
        }
    }
    .activeCard::after {
        @include respond(desktop) {
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: -1px;
            right: -1px;
            background: linear-gradient(to left, rgba(0, 0, 0, 0), #646464);
            content: "";
            z-index: -1;
            border-radius: 10px;
        }
    }

    .cardDescription {
        display: none;
    }

    .section-our-vision-v2 {
        padding: 0px !important;
    }

    .apitagcards-row-v2 {
        margin: 0px !important;
    }

    .designCard-wrapper {
        gap: 16px;
    }

    @include respond(ipadMobile) {
        .designCard-wrapper {
            gap: 8px;
        }
    }

    .designCard {
        padding: 28px 24px;
        gap: 10px;
        background: linear-gradient(
            90deg,
            rgba(1, 120, 252, 0.05) 41.46%,
            rgba(1, 120, 252, 0) 100%
        );

        @media screen and (min-width: 721px) {
            flex-grow: 1;
        }
        border-radius: 10px;
        font-size: 16px;
    }

    .designCardScroll {
        background: linear-gradient(
            90deg,
            rgba(226, 1, 252, 0.15) 41.46%,
            rgba(252, 5, 252, 0.00266908) 99.99%,
            rgba(252, 93, 1, 0) 100%
        );
        border-radius: 10px;
        transition: background 800ms cubic-bezier(0.19, 1, 0.22, 1);
        font-size: 16px;
    }
    .designDoc {
        &__title {
            font-weight: 600;

            @include respond(desktop) {
                font-size: 36px;
            }
            @include respond(ipad) {
                font-size: 28px;
            }
            @include respond(mobile) {
                font-size: 24px;
            }
        }
        &__subtitle {
            font-size: 18px;
            @include respond(mobile) {
                font-size: 14px;
            }
        }
    }
    .designDevQuote {
        position: relative;
        border: 1px solid transparent;
        border-radius: 10px;
        background: rgba(0, 0, 0, 1);
        background-clip: padding-box;
        text-align: center;
        padding: 20px;
        @include respond(mobile) {
            padding: 10px;
        }
    }

    .astrum-agent-details {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
    }

    .devFadingText {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 130%;
        text-align: center;

        background: linear-gradient(
            to bottom,
            #f5f5f5 8.85%,
            rgba(245, 245, 245, 0.64) 93.23%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        padding: 32px 24px 32px;
    }

    @include respond(mobile) {
        .astrum-agent-details {
            font-size: 16px !important;
        }

        .devFadingText {
            font-size: 20px;
        }
    }

    .designDevQuote::after {
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        background: linear-gradient(to left, rgba(0, 0, 0, 0.3), #646464);
        content: "";
        z-index: -1;
        border-radius: 10px;
    }
    .designContainer {
        @include respond(mobile) {
            display: none;
        }
        @include respond(ipad) {
            display: none;
        }
    }

    .designWrap {
        margin-bottom: 10%;
    }

    .designContainerMobile {
        @include respond(desktop) {
            display: none;
        }
        @include respond(largedesktop) {
            display: none;
        }

        // @include respond(smallmobile) {
        //     display: none;
        // }
    }

    .designDevQuote {
        @include respond(desktop) {
            .quote-img {
                width: 48px;
                height: 36px;
            }
        }
        @include respond(ipad) {
            margin-bottom: 32px;
        }
        @include respond(mobile) {
            margin-bottom: 32px;
        }
    }

    .devMainSmallMobile {
        margin-top: 50px;
        @include respond(desktop) {
            display: none;
        }
        @include respond(largedesktop) {
            display: none;
        }
        @include respond(ipad) {
            display: none;
        }
        @include respond(mobile) {
            display: none;
        }
    }

    .aboutUsOurTeam {
        .heading {
            background: linear-gradient(
                180deg,
                #f5f5f5 28.25%,
                rgba(245, 245, 245, 0.64) 92.07%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
        .subheading {
            background: linear-gradient(
                180deg,
                #f5f5f5 28.25%,
                rgba(245, 245, 245, 0.64) 92.07%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-weight: 600;
            font-size: 20px;
            text-align: center;
            margin-top: 15px;
        }
    }

    .individualCard {
        width: 261px;
        margin: 10px;
        margin-top: 50px;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        cursor: pointer;
        display: inline-block;

        .dummyclass {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                332.8deg,
                rgba(0, 194, 255, 0.05) 21.27%,
                rgba(0, 90, 249, 0.05) 63.28%
            );
            opacity: 0;
            transition: opacity 500ms ease-out;
        }

        .lower {
            height: 82px;
            background: linear-gradient(
                332.8deg,
                rgba(0, 194, 255, 0.05) 21.27%,
                rgba(0, 90, 249, 0.05) 63.28%
            );
            // border:35px 35px 4px 4px;
            border-top-left-radius: 35px;
            border-top-right-radius: 35px;
            .name {
                color: #ffffff;
                font-size: 15.9601px;
                font-weight: 600;
                line-height: 28px;
                font-family: "Inter";
                font-style: normal;
                margin-bottom: 5px;
            }
            .position {
                color: #0091ff;
                font-weight: 600;
                font-size: 13.9651px;
                font-family: "Inter";
                font-style: normal;
            }
        }
        .upper {
            .aboutUsImageParent {
                margin-bottom: -15px;
                .aboutusImage {
                    border-radius: 50%;
                    height: 105px;
                    width: 105px;
                }
            }
        }

        &:hover {
            padding-top: -20px;
            padding-bottom: -20px;
            transform: scale(1.04);
            .dummyclass {
                opacity: 1;
            }
            .lower {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                background: none !important;
                margin-top: 15px;
                .position {
                    margin-bottom: 20px;
                }
            }
            .upper {
                .aboutUsImageParent {
                    // .aboutusImage{
                    //     height: 115px;
                    //     width: 115px;
                    // }
                }
                .aboutusImage {
                    margin-top: -10px;
                }
            }
        }
    }

    @keyframes card {
        0% {
            background-color: red;
            left: 0px;
            top: 0px;
        }
        25% {
            background-color: yellow;
            left: 200px;
            top: 0px;
        }
        50% {
            background-color: blue;
            left: 200px;
            top: 200px;
        }
        75% {
            background-color: green;
            left: 0px;
            top: 200px;
        }
        100% {
            background-color: red;
            left: 0px;
            top: 0px;
        }
    }
}

.v1--styles {
    // .ourVisionHeading{
    //     @include respond(laptop){
    //         margin-top: 30px;
    //     }
    // }

    .aboutUsHeading {
        @include respond(desktop) {
            margin-top: 70px !important;
        }
    }

    @media only screen and (max-width: 900px) {
        .aboutUsHeading {
            margin-top: 70px !important;
            padding-top: 0px !important;
        }
    }
    .productCard {
        padding: 64px 0;
        display: flex;
        align-items: center;

        .productCol {
            width: 50%;

            &.two {
                padding-left: 32px;
            }
        }
    }

    @include respond(desktop) {
        &.productCards--section {
            .productCard:nth-child(even) {
                flex-direction: row-reverse !important;
                .productCol {
                    &.two {
                        padding-left: 0px;
                        padding-right: 32px;
                    }
                }
            }
        }
    }
    .productInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .productTitle {
        background: linear-gradient(
            90.08deg,
            #f6f6f6 0.79%,
            rgba(246, 246, 246, 0.6) 84.86%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 38px;
    }
    .gradientLine {
        width: 100%;
        height: 4px;
    }
    .productTagline {
        font-weight: 500;
        font-size: 20px;
        line-height: 140%;
        color: #c2c2c2;
    }
    .productDescription {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: #c2c2c2;
    }

    @media screen and (max-width: 768px) {
        .productInfo {
            padding: 20px 0;
        }
        .productCard {
            padding: 32px 0;
            margin: 0 auto;
            flex-direction: column;
            .productCol {
                width: 95%;
                &.two {
                    padding-left: 0;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .productCard {
            padding: 24px 0;
        }
    }

    @include respond(smbootstrap) {
        .productInfo {
            .productTitle {
                font-size: 24px;
            }
        }
    }
    // .aboutPageImage{
    //     margin-top: 10px;

    // }

    // }
}

.apiwiz-tags-section {
    margin-bottom: rem(100);
    @include respond(ipadMobile) {
        margin-bottom: rem(25);
    }
}

.footer-contactus-section {
    overflow: hidden;

    .footer-demo-img {
        width: 922.41px;
        height: 471.75px;
        @include respond(1440orlower) {
            height: 450.75px;
        }
        @include respond(2400orhigher) {
            display: none !important;
        }
    }

    .left-img {
        left: 0;
        bottom: 0;
        @include respond(1440orlower) {
            left: -50px;
        }
    }

    &.v2{
        .left-img{
            @include respond(1440orlower) {
                bottom: 90px;
            }
        }
        .right-img{
            @include respond(1440orlower) {
                bottom: 90px;
            }
        }
    }

    .right-img {
        right: 0;
        bottom: 0;
        @include respond(1440orlower) {
            right: -50px;
        }
    }
}

.grid-overlay-img{
    overflow: hidden;
    top: -150px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: rem(1324);
    height: rem(708);
    max-width: rem(1324);
    max-height: rem(708);
}

.footer-contactus-container {
    width: rem(892);
    height: rem(316);
    background: linear-gradient(286.54deg, #3b289e 7.59%, #b83bf7 100%);
    box-shadow: -16px 4px 24px rgba(0, 0, 0, 0.25),
        16px 0px 24px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding-top: rem(64);
    margin-bottom: rem(72);
    overflow: hidden;

    &.v2{
        height: fit-content;
        padding-top: 32px;
        padding-bottom: 32px;
    }

    @include respond(1440orlower) {
        margin-bottom: rem(55);
    }

    @include respond(ipadMobile) {
        padding: 31px;
        margin: 0 rem(48);
        height: max-content;
        margin-bottom: rem(32);
        width: 90%;
    }

    .grainy-overlay {
        top: 0;
        left: 0;
        width: rem(892);
        z-index: 1;
        height: 100%;
        background-image: url("/images/homepage/grain.webp");
        background-repeat: repeat;
        background-size: 200px 100px;
        background-position: left center;
        @include respond(ipadMobile) {
            width: 90vw;
        }
    }


    .tile-overlay{
        top: 0;
        left: 0;
        width: rem(892);
        height: 100%;
        opacity: 0.1;       
        mask-position: -70px 0; 
        -webkit-mask-position: -70px 0; 
        mask-image: linear-gradient(135.84deg, rgba(255, 255, 255, 0) 37.91%, #B3B3B3 44.79%, rgba(255, 255, 255, 0) 52.81%);
        mask-size: 462px 100%;
        -webkit-mask-image:  linear-gradient(135.84deg, rgba(255, 255, 255, 0) 37.91%, #B3B3B3 44.79%, rgba(255, 255, 255, 0) 52.81%);
        background-image: url('/images/homepage/tile-bg.webp');
        background-repeat: repeat;
        background-size: 244px 238px;
        mask-repeat: no-repeat;
        @include respond(ipadMobile){
            width: 90vw;
        }
    }

    .tile-mask-overlay{
        top: 0;
        left: 0;
        z-index: 3;
        width: rem(892);
        height: 100%;
        background: linear-gradient(140deg, rgba(0,0,0,0.015865721288515378) 77%, rgba(0,0,0,1) 90%, rgba(0,0,0,1) 91%, rgba(0,0,0,0) 99%, rgba(8,2,2,0.0018601190476190688) 288%);
    }

    .content-wrapper-cufooter {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        z-index: 1;
        @include respond(ipadMobile) {
            width: 75%;
            // max-width: rem(474)
        }
        @include respond(smbootstrap) {
            width: 90%;
        }
    }

    .footer-form-card{
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.10);
        backdrop-filter: blur(3.5px);
        width:  588.5px;
    }

    .footer-form-card input{
        width:266.5px;
        border-radius: 4px;
        background: #33216E;
        border: none;
        height: 40px;
        color:#fff;
    }

    .footer-form-card textarea{
        width:266.5px;
        border-radius: 4px;
        background: #33216E;
        border: none;
        color:#fff;
    }

    .footer-form-card textarea::placeholder{
        color:#fff;
        opacity: 0.8;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }

    .footer-form-card input::placeholder{
        color:#fff;
        opacity: 0.8;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }


    .footer-card-title {
        font-family: "Clash Grotesk";
        font-style: normal;
        font-weight: 500;
        font-size: 32px;

        &.v2{font-size: 24px;}
        text-align: center;
        color: #ffffff;
        letter-spacing: 0.02em;
        margin: 0;

        @include respond(ipadMobile) {
            line-height: 45px;
        }

        @include respond(smbootstrap) {
            font-size: 24px;
            line-height: 34px;
        }

        &.v2{
            font-weight: 600;
            line-height: 120% !important;
        }
    }

    .footer-card-desc {
        font-weight: 500;
        margin-top: rem(27);
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        max-width: 53%;

        @include respond(ipadMobile) {
            width: 100%;
            max-width: 100%;
            margin-top: 0;
        }

        @include respond(smbootstrap) {
            font-size: 14px;
        }
    }

    .btns-wrapper {
        flex-direction: row;
        justify-content: center;
        @include respond(ipadMobile) {
            flex-direction: column;
            align-items: center;
        }
    }

    .footer-card-btn {
        width: 227px;
        height: 48px;
        margin: 0 12px;
        margin-top: 22px;
        border-radius: 200px;
        border: none;
        outline: none;
        font-size: 16px;

        &.secondary {
            color: #e4e4e4;
            font-weight: 600;
            font-size: 16px;
            background: linear-gradient(
                270deg,
                rgba(191, 191, 191, 0.2) 0%,
                rgba(191, 191, 191, 0.5) 100%
            );
        }

        &.primary {
            font-weight: 600;
            color: #212121;
        }
    }
}

.wpeoplesay-section {
    margin-bottom: rem(88);
    .purplegradient-text {
        font-weight: 500;
        font-size: 28px;
        line-height: 38px;
        text-align: center;
        background: linear-gradient(
            180deg,
            #eed3fc 39.34%,
            rgba(238, 211, 252, 0.2) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-shadow: 0px -5px 12px rgba(0, 0, 0, 0.1);
        margin-bottom: rem(48);
    }
    .wpeoplesay-row {
        gap: 18px;
        margin: 0;
        padding: 0 15px;

        @include respond(mobile) {
            flex-direction: column;
            align-items: center;
        }
    }
}

.home-tabs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 38px;
    margin-bottom: 47px;

    @include respond(mobile) {
        // justify-content: flex-end;
        width: 100%;
    }

    > .outer-layer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 4px;
        gap: 8px;
        border-radius: 4px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
        @include respond(mobile) {
            width: 90%;
        }

        background: linear-gradient(
            180deg,
            rgba(40, 42, 54, 0.5) 6.4%,
            rgba(26, 28, 37, 2) 100%
        );
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
        > .home-tab {
            box-sizing: border-box;
            /* Auto layout */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            padding-right: 0px;
            gap: 10px;
            // width: 76px;
            height: 30px;
            width: max-content;

            border-radius: 6px;
            > div {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 100%;
                display: flex;
                align-items: center;
                padding-right: 16px;
                color: #7a7a7a;
                white-space: nowrap;
            }

            &.active {
                position: relative;
                border-radius: 6px;
                background-clip: padding-box;
                background: #2f3135;
                // padding-right: 16px;
                border: none;

                > div {
                    border: none;
                    // padding-right: 0px;
                    color: #fbfbfb;
                }
            }
            &.active::after {
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: -1px;
                right: -1px;
                background: linear-gradient(
                        307.23deg,
                        rgba(0, 194, 255, 0) 0%,
                        #5f00f9 100%
                    ),
                    linear-gradient(0deg, #2f3135, #2f3135);

                content: "";
                z-index: -1;
                border-radius: 6px;
            }
        }

        > .home-tab:not(:last-child) {
            > div {
                border-right: 0.5px solid #464646;
            }
            &.active > div {
                border: none !important;
            }
        }
    }
}

// .partner-list {
//     > p {
//         font-family: "Inter";
//         font-style: normal;
//         font-weight: 500;
//         font-size: 28px;
//         line-height: 38px;
//         /* identical to box height, or 136% */
//         text-align: center;

//         background: linear-gradient(
//             90.15deg,
//             #f6f6f6 33.83%,
//             rgba(246, 246, 246, 0.6) 67.49%
//         );
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//         background-clip: text;
//         text-fill-color: transparent;
//     }
// }

.home-main-section-circle-loop-loading {
    width: 54px;
    height: 54px;
    position: relative;

    > .icon-img-container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        height: 54px;
        width: 54px;
    }

    .hold {
        position: absolute;
        width: 100%;
        height: 100%;
        clip: rect(0px, 54px, 54px, 27px);
        border-radius: 100%;
    }
    .fill {
        background-color: transparent;
        border: 1.5px solid #fff;
    }
    .fill {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        clip: rect(0px, 27px, 54px, 0px);
    }
    .left .fill {
        z-index: 1;
        -webkit-animation: left 2s linear;
        -moz-animation: left 2s linear;
        animation: left 2s linear both;
    }
    @keyframes left {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @-webkit-keyframes left {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(180deg);
        }
    }
    .right {
        z-index: 3;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .right .fill {
        z-index: 3;
        -webkit-animation: right 2s linear;
        -moz-animation: right 2s linear;
        animation: right 2s linear both;
        -webkit-animation-delay: 2s;
        -moz-animation-delay: 2s;
        animation-delay: 2s;
    }
    @keyframes right {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
    @-webkit-keyframes right {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(180deg);
        }
    }
}

.find-out-how-btn.outer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 50px;
    background: linear-gradient(270deg, #009bcc 10%, #5f00f9 80%);
    border-radius: 4px;

    .inner {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 223px;
        height: 48px;
        border-radius: 4px;
        background: #1a1c25;
    }
    .find-out-how-btn-text {
        width: 103px;
        height: 16px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #e0e0e0;
    }
}

.section-divider {
    position: relative;
    width: 100%;
    height: max-content;

    .seperator-img {
        width: 100%;
        height: rem(155);
        @include respond(smbootstrap) {
            height: rem(78);
        }
    }
}

.join-ecosystem {
    margin-top: rem(90);
}

$pitch-indicator-width: 100%;

.pitch-type {
    padding-bottom: 41px;
    @include respond(desktopIpad){
        transition: width opacity 1s ease-in;
        &.active-type {
            width: 240px;
            animation: parentGrowth 0.5s ease;
        }
    }

    .pitch-type-arrow {
        transition: opacity 0.25s ease-out;
    }

    .pitch-type-arrow.show {
        opacity: 1;
    }

    .pitch-type-arrow.hide {
        opacity: 0;
    }

    .pitch-type-arrow-left {
        position: absolute;
        left: 10px;
        top: 45px;

        @include respond(mobile) {
            display: none;
        }
    }

    .pitch-type-arrow-right-1 {
        position: absolute;
        right: 160px;
        top: 45px;

        @include respond(mobile) {
            display: none;
        }
    }
    .pitch-type-arrow-right-2 {
        position: absolute;
        right: 40px;
        top: 45px;
        @include respond(mobile) {
            display: none;
        }
    }
}

.pitch-type-arrow-right-mobile {
    display: none;

    @include respond(mobile) {
        display: block;
        position: absolute;
        right: 10%;
        top: 70px;
    }
}

.pitch-type-container {
    gap: 30px;
    position: relative;
    @include respond(desktopIpad){
        width: rem(582);
        justify-content: center;
    }
    @include respond(mobile) {
        overflow-x: scroll;
        width: 80%;

        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .active-type {
            flex-shrink: 0;
            width: 100%;
            visibility: visible;
        }
        .inactive-type {
            flex-shrink: 0;
            width: 100%;
            visibility: hidden;
        }
    }
}

.faded-pitch-type-indicator {
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background: #272727;
    left: 0;
    z-index: 1;
}


.pitch-type-indicator {
    z-index: 2;
    left: 0;
    width: 0px;
    height: 2px;
    border-radius: 4px;
    background: #d9d9d9;
    animation: none;
    &.active {
        width: $pitch-indicator-width;
        animation: growth 4s ease;
    }

    @include respond(mobile) {
        &.active {
            width: 100%;
            animation: growth 4s ease;
        }
    }
}

.pitch-type:hover {
    cursor: pointer;
    .pitch-type-indicator.active {
        animation-play-state: paused;
        -webkit-animation-play-state: paused;
    }
}

@keyframes growth {
    0% {
        width: 0px;
    }

    100%{
        width: $pitch-indicator-width;
    }
}

@keyframes shrink {
    0% {
        width: $pitch-indicator-width;
    }

    100% {
        width: 120px;
    }
}

@keyframes parentGrowth {
    0% {
        width: 0px;
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: 240px;
    }
}

.pitch-type-category {
    height: 17px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #7a7a7a;
    &.active {
        color: #b8b8b8;
    }
}

.pitch-type-title {
    height: 22px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #7a7a7a;
    &.active {
        color: #f1f1f1;
    }
}

.pitch-type-desc {
    width: 240px;
    height: 51px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #b8b8b8;
}

.pitch-type-card-container {
    gap: 62px;

    @include respond(ipadMobile) {
        padding: 0 24px;
    }
    @include respond(mobile) {
        flex-direction: column;
        gap: 14px;
        padding: 0 12px;
    }
}

.pitch-type-card {
    width: 50%;
    max-width: 38.25rem;
    min-height: 193px;

    @include respond(mobile) {
        width: 100% !important;
    }

    &.outer {
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &.first {
        background: linear-gradient(230deg, #493e79 50%, transparent 70%);
    }
    &.second {
        background: linear-gradient(to bottom, #493e79 30%, transparent 90%);
    }

    &.third {
        background: linear-gradient(to right, #493e79 60%, transparent 70%);

        @include respond(ipadMobile) {
            display: none;
        }
    }

    .outer-layer {
        background: #282337;
        border-radius: 6px;
        min-height: 193px;

        @include respond(mdmobile) {
            width: 100% !important;
        }
    }

    .inner {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // padding: 0px 24px;
        gap: 8px;
        min-height: 193px;
        background: rgba($color: #000000, $alpha: 0.45);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            8px 8px 32px rgba(0, 0, 0, 0.25), 4px 4px 4px rgba(0, 0, 0, 0.25),
            -5px 19px 22px rgba(0, 0, 0, 0.25);
        border-radius: 6px;

        @include respond(mdmobile) {
            width: 100% !important;
        }
    }

    .top-quotes-cont {
        height: 24px;
    }

    .bottom-quotes-cont {
        height: 24px;
    }
}

.pitch-type-card-connector {
    img {
        @include respond(mdmobile) {
            transform: rotate(90deg);
            height: 3px !important;
        }
    }
}

.pitch-type-card-connector:nth-last-child(2) {
    @include respond(ipadMobile) {
        display: none !important;
    }
}

.pitch-quotes-outer-cont {
    padding: 0 24px;
    flex: 1;
    transition: opacity 1s ease-in;
}

.pitch-quotes-outer-cont.fade-out {
    opacity: 0;
}

.pitch-quotes-outer-cont.fade-in {
    opacity: 1;
}

.pitch-quotes {
    flex: 1;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 170%;
    /* or 20px */
    text-align: center;
    letter-spacing: -0.02em;
    color: #f1f1f1;
    margin-bottom: 1rem;
}
.pitch-quotes-author-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 48px;
}
.pitch-quotes-author {
    height: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #f1f1f1;
}
.pitch-quotes-designation {
    height: 24px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: #c2c2c2;
}

.ecosystem-header-1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    /* identical to box height, or 136% */
    text-align: center;

    background: linear-gradient(
        90.15deg,
        #f6f6f6 33.83%,
        rgba(246, 246, 246, 0.6) 67.49%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.ecosystem-sub-header-1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */
    text-align: center;

    /* Dark/Text/400 */
    color: #c2c2c2;
}

.ecosystem-icons-cont {
    width: 912.77px;
    height: auto;

    @include respond(ipadMobile) {
        width: auto;
        height: auto;
    }

    .api-ecosystem-image {
        width: 100%;
        height: 100%;
    }
}

.main-section {
    .cloud-how {
        top: -48px;
        left: -53px;
        @include respond(ipadmd) {
            display: none;
        }
        @include respond(ipadMobile) {
            display: none;
        }
    }
}


.legal-pages-section {
    display: flex;

    .legal-page-menu {
        height: max-content;
        position: sticky;
        top: 70px;
        

        .legal-page-menu-body {
            @include respond(1260orlower){
                margin-top: 16px !important;
            }
        }

        .header-2 {
            @include respond(ipadMobile){
                font-size: 18px !important;
            }
        }

        @include respond(1260orlower){
            width: 100% !important;
            position: relative;
            top: 0;
        }
    }

    @include respond(1260orlower){
        flex-direction: column;
        
        align-items: center;
    }
}

.main-section#the-how {
    padding-top: 4.5rem;

    @include respond(ipadMobile) {
        padding-top: 4.5rem;
    }

    @include respond(mobile) {
        padding-top: 4.5rem;
    }
}
.aboutUs-v1--Card {
    height: 230px;
    width: 261px;
    padding: 16px 14px;
    padding-top: 36px !important;
    isolation: isolate;
    // background: #212121;
    order: 2;
    flex-grow: 0;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Card--image {
        width: 105.23px;
        height: 105.23px;
        margin: -18px 0px;
        border-radius: 100%;
        transition: all 400ms ease-out;
        object-fit: cover;
    }
    .Card--Content {
        margin-top: 6px;
        background: linear-gradient(
            332.8deg,
            rgba(0, 194, 255, 0.05) 21.27%,
            rgba(0, 90, 249, 0.05) 63.28%
        );
        border-radius: 35px 35px 4px 4px;
        padding: 16px 8px 8px;
        width: 100%;
        transition: all 400ms ease-out;
        .Card--name {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            text-align: center;
            color: #ffffff;
        }
        .Card--designation {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            color: #0091ff;
        }
    }

    &:hover {
        background: linear-gradient(
            332.8deg,
            rgba(0, 194, 255, 0.05) 21.27%,
            rgba(0, 90, 249, 0.05) 63.28%
        );

        .Card--image {
            transform: scale(1.1);
            transition: all 400ms ease-in;
        }

        .Card--Content {
            margin-top: 18px;
            background: none;
            transition: all 400ms ease-in;
        }
    }
}
.aboutUsVision {
    background: linear-gradient(360deg, #005af9 -41.67%, #00c2ff 116.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

.font-clash-grotesk {
    font-family: "Clash Grotesk";
}

.aboutUsvalues {
    color: rgba(246, 246, 246, 1);
    font-family: "Inter";
    font-style: normal;
}

.aboutUsvaluesSubHeader {
    color: rgba(122, 122, 122, 1);
    font-family: "Inter";
    font-style: normal;
}

.timelineContainer {
    display: flex;
    align-items: center;
    width: 100%;
    .timelineLeft {
        width: 50%;
    }
    .timelineRight {
        width: 50%;
    }
}

@media screen and (max-width: 769px) {
    .timelineContainer {
        flex-direction: column-reverse;
        .timelineLeft {
            width: 100%;
        }
        .timelineRight {
            width: 100%;
        }
    }
}

@media screen and (max-width: 600px) {
    .aboutUsNewsLetter {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

@media only screen and (max-width: 1155px) and (min-width: 1024px) {
    .aboutUsNewsLetter {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}

@media only screen and (max-width: 450px) {
    .join-us-btn-mobile {
        margin-top: 2rem;
    }
}

.aboutUsCardsDiv {
    display: flex;
    justify-content: center;
    gap: 24px;
}
@media screen and (max-width: 1024px) {
    .freesignupRoute {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .aboutUsCardsDiv {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
}

@media only screen and (max-width: 720px) {
    .about-us-card__image {
        background: none;
    }
}

@media only screen and (max-width: 720px) {
    .about-us-card__image {
        background: none;
    }

    .aboutUsIndividualImg {
        object-fit: contain !important;
    }
}


.pricing-tagline {
    line-height: 1.4;
}
.bg1{
    background: #18191B !important;
}
.bg2{
    background: #1D1E23 !important;
}
.supportMainTable{
    td{
        padding: 18px 12px;
        border-bottom: 1px solid rgba($color: white, $alpha: 0.10);

        @include respond(desktop){
            .fs-12px{
                font-size: 14px !important;
            }
        }
    }
}

.section-title-v2 {
    font-family: "Inter";
    font-size: rem(36);
    font-weight: 600;
    line-height: 1.3;
    text-align: center;

    @include respond(ipadmd){
        font-size: rem(24);
    }

    @include respond(ipadMobile){
        font-size: rem(24);
    }
}

.subtitle-v2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #E4E4E4 !important;

    @include respond(mobile){
        font-size: rem(16);
    }
}

.legal-menu-item {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 16px;
    cursor: pointer;

    &.active {
        background-color: #292A2F;
        font-weight: 500;
    }
}


.certificate-title{
    font-size: 48px;
    @media screen and (max-width: 767px) {
        font-size: 30px;
    }
}

.main-certificate-card{
    gap: 42px;
    padding: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: start;
    background: #292A2F;
    border-radius: 24px;
    width: 865px;

    .main-certificate-card-subitem{
        display: flex;
        flex-direction: column;
        gap: 12px;

        .subitem-header{
            font-weight: 600;
            color: #fff;
            font-size: 32px;
            @include respond(smbootstrap) {
                text-align: center;
                font-size: 24px;
            }
        }

        .subitem-subheader{
            font-weight: 500;
            font-size: 18px;
            color: #fff;
            @include respond(smbootstrap) {
                text-align: center;
                font-size: 16px;
            }
        }

        .subitem-tag{
            background: #5746AF;
            padding: 6px 16px;
            gap: 10px;
            border-radius: 99px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            width: max-content;
            color: #fff;
            @include respond(smbootstrap) {
                margin: auto;
            }

        }
    }

    @media screen and (max-width: 992px) {
        width: 100%;
    }
}



@media screen and (max-width: 500px) {
    .main-certificate-card{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

.blog-details-desc p.color-666{color: #666666 !important;}

.section-blog-details-1{
    @media screen and (max-width: 920px) {
        max-width: 690px !important;
    }
}

.blog-details-desc-internal{
    @media screen and (max-width: 825px) {
        justify-content: center !important;
        gap:45px !important
    }
}

.justify-content-evenly{
    justify-content: space-evenly;
}
.customTArea:focus{
    background: #33216E;

    color: white;
}

@media only screen and (max-width: 768px) {
    .cardForm{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .cardCustomInput{
        width: 100% !important;
    }

    .footer-form-card{
        width: 330px !important;
    }
}

@media only screen and (max-width: 400px) {
    .footer-form-card{
        width: 250px !important;
    }
}


.purpleBgg1{
    position: absolute;
    left: -55px;
    top: -10px;
    border-radius: 24px;
    opacity: 0.5;
    background: #4F419A;
    height: 400px;
    width: 600px;
}

.purpleBgg2{
    position: absolute;
    left: -20px;
    top: -25px !important;
    border-radius: 24px;
    opacity: 0.5;
    background: #4F419A;
    height: 400px;
    width: 540px;
    top: -10px;
}

// @media only screen and (max-width: 720px){
//     // .purpleBgg1{
//     //     height: 217.5px;
//     //     width: 260px;
//     //     top: 0px;
//     // }

//     // .purpleBgg2{
//     //     height: 217.5px;
//     //     width: 200.923px;
//     //     top: -10px;
//     // }
// }

.btnContainer{
    background-color: #1e303f;
    background: var(--dark-surface-base, #1D1E23);
    border-radius: 7px;
    border: 1px solid var(--dark-strokes-800, #464646);
    width: 444px;
    align-items: center;
    justify-content: space-between;
    padding: 6px 4px 6px 24px;
    height: 50px;
}

.emailInput{
    background: var(--dark-surface-base, #1D1E23);
    border: none;
    color: var(--dark-strokes-600, #7A7A7A);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    outline: none;
    width: 100%;
}

.emailInput:-webkit-autofill { 
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--dark-strokes-600, #7A7A7A);
}

.formcls{
    width: 60%;
}

.ebookbtn{
    display: flex;
    padding: 13px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #725CE0;
    border: none;
    color: #FFF;
    font-family: "Inter";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 10px;
    width: 45%;
}

.emptyEmail{
    color: #FF8080;
    font-family: Inter;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: 140%;
}

@media only screen and (min-width: 720px) and (max-width: 1300px){
    .purpleBgg1{
        height: 217.5px;
        width: 260px;
        top: 0px;
    }

    .purpleBgg2{
        height: 217.5px;
        width: 200.923px;
        top: -10px !important;
    }
    .emptyEmail{
        font-weight: 400;
    }
    .ebookbtn{
        padding:10px 0px;
        font-size: 10px;
        width: 40%;
        
    }
    .btnContainer{
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        background-color: none;
        background: none;
        border: none;
        padding: 0;
    }
    .emailInput{
        padding: 7px;
    }

    .btn-ebook{
        width: 100%;
    }
    .formcls{
        width: 100%;
    }
    .emailInput{
        width: 80%;
        margin-right: 10px;
        border-radius: 7px;
        border: 1px solid var(--dark-strokes-800, #464646);
        height: 32px;
        font-size: 10px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 719px){
    .purpleBgg1{
        height: 217.5px;
        width: 260px;
        top: 0px;
    }

    .purpleBgg2{
        height: 217.5px;
        width: 200.923px;
        top: -10px !important;
    }
    .emptyEmail{
        font-weight: 400;
    }
    .btn-ebook{
        width: 100%;
        padding-left: 12%;
        padding-right: 12% ;
    }
    .ebookbtn{
        padding:8px 0px;
        font-size: 10px;
        width: 100%;
    }
    .btnContainer{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: none;
        background: none;
        border: none;
        padding: 0;
    }
    .emailInput{
        padding: 7px;
    }

    .btn-ebook{
        width: 100%;
    }
    .formcls{
        width: 100%;
    }
    .emailInput{
        width: 100%;
        border-radius: 7px;
        border: 1px solid var(--dark-strokes-800, #464646);
        height: 32px;
        font-size: 10px;
        margin-bottom: 10px;
    }
    .emptyEmail{
        margin: 0px;
        margin-top: 30px;
        font-size: 12px;
    }

}

.calendyLink{
    margin-right: -22px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    background: var(--dark-colors-blue-09, #0091FF);
    border: none;
    color: white;
    color: var(--dark-text-300, #E4E4E4);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
}

.text-danger-2{
    color: #FF8080;
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
    margin-top: 2px;
    margin-bottom: 0px;
}

.flip-card {
    perspective: 1000px;

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.4s;
        transform-style: preserve-3d;
    }

    &.isSuccess .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
    }
    
    .flip-card-back {
        transform: rotateY(180deg);
    }
}

.v1select{
    .custom-option-styles{
        padding: 8px;
        display: flex;
        align-items: center;
        .option-revision-badge{
            display: flex;
            width: max-content;
            padding: 2px 8px;
            justify-content: center;
            line-height: 100%;
            font-size: 12px;
            margin-left: 12px;
            align-items: center;
            border-radius: 38px;
            border: 0.5px solid var(--dark-surface-700);
            background: var(--dark-surface-850);
        }
    }
   
    .custom-option-styles:hover{
        background-color: var(--dark-surface-900);
    }
    
   

    span[class$="indicatorSeparator"] {
        display: none;
    }

    div[class$="control"] {
        background: var(--dark-surface-875) !important;
        outline-width: 0 !important;
        border: 1px solid var(--dark-surface-800) !important;
        height: 40px !important;
        * {
            color: white;
        }
    }

    &.h-fit{
        div[class$="control"] {
            height: fit-content !important;
        }
    }

    &.border-right-0 {
        div[class$="control"] {
            background: var(--dark-surface-850) !important;
            border-right: 0px solid transparent !important;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }
    }

    &.border-left-0 {
        div[class$="control"] {
            border-left: 0px solid transparent !important;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
        }
    }

    &.h-auto {
        div[class$="control"] {
            height: fit-content !important;
        }
    }
    &.h-32 {
        div[class$="control"] {
            height: 32px !important;
            min-height: 32px !important;

            div[class$="ValueContainer"],
            > div:nth-child(1) {
                min-height: 32px;
                padding: 1.5px 8px 4px 4px;
            }
            div[class$="IndicatorsContainer"],
            > div:nth-child(2) {
                min-height: 30px;
                height: 30px;
                div[class$="indicatorContainer"] {
                    padding: 4px;
                }
            }
        }
    }

    &.h-40 {
        div[class$="control"] {
            height: 40px !important;
            min-height: 40px !important;
        }
    }

    div[class$="menu"],
    div[class$="control"],
    div[class$="singleValue"],
    div[class$="Input"] {
        font-size: 14px;
    }

    div[class$="multiValue"] {
        background: var(--dark-surface-base) !important;
    }
    &.hide-labels {
        div[class$="multiValue"] {
            display: none;
        }
        div[class$="IndicatorsContainer"] {
            div[class$="indicatorContainer"]:first-child {
                display: none;
            }
        }
    }

    div[class$="menu"] {
        cursor: pointer;
        background: var(--dark-surface-base) !important;
        div[class$="option"] {
            background-color: var(--dark-surface-base);
            color: white;
        }
        div[class$="option"].focused {
            background-color: var(--dark-surface-900);
        }
        div[class$="option"].selected {
            background-color: var(--dark-surface-850);
        }
    }

    &.checkbox-multi {
        div[class$="menu"] {
            background-color: var(--dark-surface-pure) !important;
            > div {
                padding: 0 4px 4px 4px;
                div {
                    padding: 0;
                    border-radius: 3px;
                }
            }
            div[class$="option"] {
                background-color: var(--dark-surface-pure) !important;
            }
        }
    }
}